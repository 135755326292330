html {
  color: #FFF;
  background: #020203;
  width: 100%;
  height: 100%;

  body {
    color: #FFF;
    background: #020203;
    width: 100%;
    height: 100%;
  }
}

a {
  &[href^="tel:"], &[href^="mailto:"] {
    white-space: nowrap;
  }
}

.u-select_none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-user {
  border-radius: 300px;
  border: 2px solid #E60500;
  text-align: center;
  font-weight: normal;
  font-size: 0;
  padding: 0 5px;
  display: inline-block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  color: #FFF !important;
  transition: all .2s linear;
  background: transparent;
  line-height: 32px;

  .btn-text {
    display: inline-block;
    vertical-align: middle;
    font: 300 18px/18px "Roboto", sans-serif;
    padding-top: 5px;
    padding-bottom: 5px;
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }

  &:hover {
    background: #E60500;
  }

  &.fill {
    background: #E60500;

    &:hover {
      background: #7B0203;
      border-color: #7B0203;
    }
  }
}

.btn-wrap {
  font-size: 0;
  font-weight: normal;
}

//.input {
//  display: block;
//  position: relative;
//  width: 100%;
//  input {
//    display: block;
//    position: relative;
//    width: 100%;
//    background: #1F1D2B;
//    text-align: left;
//    height: 40px;
//    transition: all .2s linear;
//    font: 400 18px/18px "Intro-Bold", sans-serif;
//    color: #FFF;
//    padding: 0 8px;
//    border: 1px solid #545050;
//  }
//  &.required:before {
//    content: '*';
//    display: block;
//    color: #C50D00;
//    font: 400 12px/12px "Roboto", sans-serif;
//    position: absolute;
//    top: 4px;
//    right: 4px;
//    -webkit-pointer-events: none;
//    pointer-events: none;
//    z-index: 2;
//  }
//  input {
//    &::-webkit-input-placeholder, &::-moz-placeholder, &:-ms-input-placeholder, &:-moz-placeholder {
//      opacity: 1;
//      transition: all .2s linear;
//    }
//    &:focus {
//      &::-webkit-input-placeholder, &::-moz-placeholder, &:-ms-input-placeholder, &:-moz-placeholder {
//        opacity: 0;
//      }
//    }
//  }
//}

input {
  &:invalid {
    box-shadow: none;
  }

  &.invalid ~ .error-tip, &:invalid ~ .error-tip {
    visibility: visible;
    opacity: 1;
  }
}

.btn-drive {
  background: #C50D00;
  width: 260px;
  min-height: 70px;
  padding: 11px 18px;
  cursor: pointer;
  outline: none !important;
  transition: all .2s linear;
  display: flex;
  display: -ms-flexbox;
  flex-direction: row;
  -ms-flex-pack: start;
  position: fixed;
  bottom: 20px;
  right: 20px;
  justify-content: flex-start;
  -ms-flex-align: center;
  align-items: center;
  flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  border-radius: 20px;
  z-index: 100;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none !important;
  opacity: 1 !important;

  > {
    .icon {
      width: 48px;
      height: 48px;
      position: relative;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      display: block;
      font-size: 0;
      font-weight: normal;

      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        display: block;
      }
    }

    .text {
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      padding-left: 36px;
      text-align: left;
      font: 400 18px/1.2 "Intro-Bold", sans-serif;
      color: #F2F3F7;
    }
  }

  &:hover {
    background: #7B0203;
  }
}

.main-wrap {
  min-height: 100%;
  width: 100%;
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: flex-start;
  -ms-flex-align: start;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  overflow: hidden;

  .my-container {
    max-width: 1206px;
    margin: 0 auto;
  }

  .main-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 100%;
    padding-top: 102px;

    h1 {
      font: 400 38px/1.2 "Intro-Bold", sans-serif;
      color: #FFF;
      text-transform: uppercase;
      text-align: left;
      letter-spacing: 1px;
      margin: 0;

      &:after {
        content: '';
        display: block;
        box-sizing: border-box;
        background: #D60307;
        width: 100%;
        max-width: 115px;
        height: 4px;
        margin-top: 15px;
        margin-bottom:15px;
      }

      @media (max-width: 800px) {
        font-size: 30px;
      }

      @media (max-width: 600px) {
        font-size: 28px;

        &:after {
          margin-top: 16px;
        }
      }
    }

    @media (max-width: 800px) {
      padding-top: 0;
    }
  }
}

@media (max-width: 700px) {
  .btn-user .btn-text, .btn-user-2 .btn-text {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }
}

@font-face {
  font-family: 'Intro-Bold';
  src: url('../fonts/Intro-Bold/Intro-Bold.eot');
  src: local("☺"), url('../fonts/Intro-Bold/Intro-Bold.woff') format("woff"), url('../fonts/Intro-Bold/Intro-Bold.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
}

table.history-table {
  th, td {
    padding: 0.3rem !important;
  }
}